.card-overlay-1{
    height: 38%;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(104px);       
    border-bottom-left-radius: 1rem;
    border-top-left-radius: 0;
    border-bottom-right-radius: 1rem;
    border-top-right-radius: 0;
    padding: 2.5rem;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    .firefox &{
      background: rgba(255, 255, 255, 0.4);
      
    }
  }

.blog-img{
  border-radius: 1rem;
  max-width: 100%;
  height: auto;   
  object-fit: cover;
}
  