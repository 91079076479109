.shadow-1 {
    background: linear-gradient(137.56deg, #FFFFFF 2.14%, rgba(245, 245, 245, 0) 102.34%), linear-gradient(136.02deg, rgba(255, 155, 62, 0.1) 0%, rgba(254, 82, 28, 0.1) 91.62%), #F2F2F2;
    box-shadow: inset 3px 2px 4px rgba(255, 255, 255, 0.7), inset -2px -2px 6px rgba(0, 0, 0, 0.06), inset -5px -6px 24px rgba(254, 82, 28, 0.11), inset 20px 21px 34px rgba(245, 194, 11, 0.2);
  }
  
.shadow-2 {
    background: linear-gradient(137.56deg, #FFFFFF 2.14%, rgba(245, 245, 245, 0) 102.34%), linear-gradient(136.02deg, rgba(255, 51, 38, 0.1) 0%, rgba(226, 162, 38, 0.1) 91.62%), #F2F2F2;
    box-shadow: inset 3px 2px 4px rgba(255, 255, 255, 0.7), inset -2px -2px 6px rgba(0, 0, 0, 0.06), inset -5px -6px 24px rgba(254, 82, 28, 0.11), inset 20px 21px 34px rgba(255, 51, 38, 0.2);
}

.shadow-3 {
    background: linear-gradient(137.56deg, #FFFFFF 2.14%, rgba(245, 245, 245, 0) 102.34%), linear-gradient(136.02deg, rgba(255, 132, 79, 0.1) 0%, rgba(248, 0, 0, 0.1) 91.62%), #F2F2F2;
    box-shadow: inset 3px 2px 4px rgba(255, 255, 255, 0.7), inset -2px -2px 6px rgba(0, 0, 0, 0.06), inset -5px -6px 24px rgba(248, 0, 0, 0.11), inset 20px 21px 34px rgba(255, 132, 79, 0.2);
}

.shadow-icon-1{
    filter: drop-shadow(17px 10px 24px  rgba(106, 17, 250, 0.6));
}
.shadow-icon-2{
    filter: drop-shadow(17px 10px 24px  rgba(180, 12, 207, 0.6));
}
.shadow-icon-3{
    filter: drop-shadow(17px 10px 24px  rgba(116, 30, 255, 0.6));
}


