.img-portfolio{
  border-radius: 1rem;
  object-fit: cover;
}






