// #preloader {
//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     z-index: 10000;
//     background: #fff url('../img/loader-3.gif') no-repeat center center;
//   }
/*-----------------------------------------------
|   Pre-loader
-----------------------------------------------*/
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10001;
  background-color: $white;
  opacity: 1;
  transition: opacity 0.8s cubic-bezier(.77, 0, .18, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  @include media-breakpoint-up(lg){
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: map_get($spacers, 8);
    padding-bottom: map_get($spacers, 6);
  ;
  }
  &.loaded{
    opacity: 0;
  }
}

.line-scale-pulse-out-rapid{
  div{
    background-color: $black;
    width: 0.125rem;
    border-radius: $border-radius;
  }
}


  #preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 10000;
  }
  
  .loader {
    position: absolute;
    width: 42px;
    height: 42px;
    // -webkit-animation: preloader_6 5s infinite linear;
    animation: preloader_6 5s infinite linear;
    left: 50%;
    top: 50%;
    margin-left: -21px;
    margin-top: -21px;
    span {
      width: 20px;
      height: 20px;
      position: absolute;
      background: #FB5668;
      display: block;
      animation: preloader_6_span 1s infinite linear;
      &:nth-child(1) {
        background: #FB5668;
      }
      &:nth-child(2) {
        left: 22px;
        background: #7946E3;
        animation-delay: .2s;
      }
      &:nth-child(3) {
        top: 22px;
        background: #F9C24D;
        animation-delay: .4s;
      }
      &:nth-child(4) {
        top: 22px;
        left: 22px;
        background: #1B90F7;
        animation-delay: .6s;
      }
    }
  }
  
  @-webkit-keyframes preloader_6_span {
    0%, 100% {
      transform: scale(1);
    }
  
    50% {
      transform: scale(0.5);
    }
  }
  
  
  @keyframes preloader_6_span {
    0%, 100% {
      transform: scale(1);
    }
  
    50% {
      transform: scale(0.5);
    }
  }