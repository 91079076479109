/*-----------------------------------------------
|   Card
-----------------------------------------------*/
.card{
  border-radius: 1rem;
  border: 0;
}
.card-link {
  font-size: map_get($font-sizes, "-1");
  font-weight: $font-weight-semi-bold;
}

.card-body:last-child {
  border-bottom-left-radius: $card-border-radius;
  border-bottom-right-radius: $card-border-radius;
}


/*-----------------------------------------------
|   Card spacer
-----------------------------------------------*/
.ps-card,
.px-card,
.p-card {
  padding-left: $card-spacer-x !important;
}
.pe-card,
.px-card,
.p-card {
  padding-right: $card-spacer-x !important;
}
.pt-card,
.py-card,
.p-card {
  padding-top: $card-spacer-x !important;
}
.pb-card,
.py-card,
.p-card {
  padding-bottom: $card-spacer-x !important;
}
.mt-card {
  margin-top: $card-spacer-x !important;
}
.mr-card {
  margin-right: $card-spacer-x !important;
}

.ms-ncard,
.mx-ncard,
.m-ncard {
  margin-left: -$card-spacer-x !important;
}
.me-ncard,
.mx-ncard,
.m-ncard {
  margin-right: -$card-spacer-x !important;
}

.card-span{
  transition: all 0.4s ease;
  border:0;
  .card-span-img{
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}
.card-img,
.card-img-top {
  @include border-top-radius(0);
}

.card-img,
.card-img-bottom {
  @include border-bottom-radius(0);
}


.card-bg-line{
  background: linear-gradient(137.56deg, #FFFFFF 2.14%, rgba(245, 245, 245, 0) 102.34%), linear-gradient(136.02deg, rgba(64, 207, 252, 0.1) 0%, rgba(106, 17, 250, 0.1) 91.62%), #F2F2F2;
  box-shadow: inset 3px 2px 4px rgba(255, 255, 255, 0.7), inset -2px -2px 6px rgba(0, 0, 0, 0.06), inset -5px -6px 24px rgba(106, 17, 250, 0.11), inset 20px 21px 34px rgba(64, 207, 252, 0.2);
  border-radius: 24px;

}
.card-bg-coffee{
  background: linear-gradient(137.56deg, #FFFFFF 2.14%, rgba(245, 245, 245, 0) 102.34%), linear-gradient(136.02deg, rgba(10, 25, 172, 0.1) 0%, rgba(153, 0, 191, 0.1) 91.62%), #F2F2F2;
  box-shadow: inset 3px 2px 4px rgba(255, 255, 255, 0.7), inset -2px -2px 6px rgba(0, 0, 0, 0.06), inset -5px -6px 24px rgba(153, 0, 191, 0.11), inset 20px 21px 34px rgba(10, 25, 172, 0.2);
  border-radius: 24px;
}

.card-bg-download{
  background: linear-gradient(137.56deg, #FFFFFF 2.14%, rgba(245, 245, 245, 0) 102.34%), linear-gradient(136.02deg, rgba(255, 55, 187, 0.1) 0%, rgba(116, 30, 255, 0.1) 91.62%), #F2F2F2;
  box-shadow: inset 3px 2px 4px rgba(255, 255, 255, 0.7), inset -2px -2px 6px rgba(0, 0, 0, 0.06), inset -5px -6px 24px rgba(116, 30, 255, 0.11), inset 20px 21px 34px rgba(255, 55, 187, 0.2);
  border-radius: 24px;

}





.team{
  position:absolute;
  top: 0;
  left: 0;
  &:hover
  {
    height: 788px;
    width: 571px;
    background: rgba(242, 242, 242, 0.2);
    backdrop-filter: blur(124px);
    border-radius: 64px;
  }

}


//- card hover overley
.card-blog {
	position: relative;
	overflow: hidden;
	box-sizing: border-box;
	// height: 12.5rem;
	margin-bottom: map_get($spacers,3);
	border-radius: 1rem;
	.card-img {
		width: 100%;
		height: 100%;
		transition: all 0.8s;
		object-fit: cover;
	}
	@include hover-focus{
		.card-img{ transform: scale(1.1); }
	}
	.overlay-gradient{
		background: none;
		
		@include hover-focus{
			background: linear-gradient(225deg, rgba(6, 26, 85, 0) 0%, rgba(1, 19, 61, 0.9) 65.07%);
		}
	}
}

