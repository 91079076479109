.icon-wrapper {
    position: relative;
    display: inline-block;
    &::before {
      position: absolute;
      content: '';
      height: 3.125rem;
      width: 3.125rem;
      border-radius: 14px;
      z-index: -1 !important;
      top: 0.375rem;
      left: 1.25rem;
    }
  }
  
.icon {
    padding: map_get($spacers, 3);
    border-radius: 15px;
    display: inline-flex;
    backdrop-filter: blur(10px);

    @each $color, $value in $theme-colors {
      &-#{$color} {
        color: $value;
      }
    }
  }