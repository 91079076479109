/*-----------------------------------------------
|   Form
-----------------------------------------------*/
label{
  font-size: map_get($font-sizes, '-1');
  font-weight: $font-weight-bold;
  letter-spacing: 0.02em;
}
// input.form-control{
//   border-image-source: linear-gradient(rgba(0, 51, 102, 0.5), rgba(0, 0, 51, 0.5));
//   border-width: 2pt;
//   border-image-slice: 1;
// }


.form-boots-control {
  border: $input-border-width solid rgba($light, .9);
  border-radius: 0.563rem;
  font-weight: 300;
  background-color: $gray-100;
  padding: .5rem .5rem;
  &:active,
  &:focus {
    color: $black;
    font-weight: 700;
    background-color: $gray-100;
    border: 1px solid #FF7A00 !important;
    box-shadow: rgba($light, .9);
    outline: 0;
  }
}
textarea.form-control{
  border: none;
  &:active,
  &:focus {
    color: $black;
    font-weight: 700;
    background-color: $gray-100;
    box-shadow: none !important;
    outline: 0;
  }
}

/*-----------------------------------------------
|   Input File
-----------------------------------------------*/
// input.zinput-file[type='file']{ display: none; }
// .zinput-file + label{
//   svg{
//     fill: currentColor;
//     vertical-align: middle;
//     margin-right: 0.5rem;
//   }
// }

/*-----------------------------------------------
|   Checkbox iso toggle
-----------------------------------------------*/
// .ios-toggle{
//   &, &:active{
//     height:0;
//     width:0;
//     opacity: 0;
//   }
//   &:checked + .checkbox-label{
//     box-shadow:inset 0 0 0 1.25rem $primary, 0 0 0 0.0625rem $primary;
//     &:before{
//       left:calc(100% - 2rem);
//       box-shadow:0 0 0 0.0625rem transparent, -0.1875rem 0 0.1875rem rgba(0,0,0,.1);
//     }
//     &:after{
//       content:attr(data-on);
//       left: 4.375rem;
//       width: 2rem;
//     }
//   }
// }

// .checkbox-label{
//   display: block;
//   position: relative;
//   padding: 0.625rem;
//   margin-bottom: 1rem;
//   font-size: map_get($font-sizes, '-1');
//   line-height: 1rem;
//   width: 4.375rem;
//   height: 2rem;
//   border-radius: $border-radius-capsule;
//   background-color: map-get($theme-colors, 1100);
//   cursor:pointer;
//   box-shadow: inset 0 0 0 0 $primary, 0 0 0 0.0625rem $gray-300;
//   transition: box-shadow 0.2s ease-in-out;

//   &:before{
//     content: '';
//     display: block;
//     position: absolute;
//     height: 2rem;
//     width: 2rem;
//     top: 0;
//     left: 0;
//     right: auto;
//     background-color: white;
//     border-radius:  $border-radius-capsule;
//     box-shadow: 0.1875rem 0 0.1875rem rgba(0,0,0,.1), 0 0 0 0.0625rem $gray-300;
//     transition:  all 0.2s ease-in-out;
//   }
//   &:after{
//     content: attr(data-off);
//     display: block;
//     position: absolute;
//     top: 0;
//     left: -18.75rem;
//     padding: 0.625rem;
//     height: 100%;
//     width: 18.75rem;
//     text-align: right;
//     color: map-get($grays, 200);
//     white-space: nowrap;
//   }
//   &.rounded{
//     border-radius: $border-radius;
//     &:before{ border-radius: $border-radius; }
//   }
// }

